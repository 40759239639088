<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("SALES_INVOICES.SALES_INVOICES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openSalesInvoiceCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_SALES_INVOICES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("SALES_INVOICES.ADD_SALES_INVOICE")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_SALES_INVOICES)"
              :objectType="'SalesInvoices'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <sales-invoice-list-table
        @onViewSalesInvoice="openSalesInvoiceViewModal"
        @onEditSalesInvoice="openSalesInvoiceEditModal"
        @onDeleteSalesInvoice="deleteSalesInvoice"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSalesInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSalesInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_INVOICE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_INVOICES.VIEW_SALES_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="validateSalesInvoice(openSalesInvoice)"
                v-if="
                  openSalesInvoiceLoaded
                    ? openSalesInvoice.items.length > 0 &&
                      openSalesInvoice.status === INVOICE_STATUS_DRAFT
                    : false
                "
                :title="$t('SALES_INVOICES.VALIDATE')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-check-bold"></i>
                </span>
              </base-button>

              <base-button
                v-if="
                  openSalesInvoiceLoaded
                    ? openSalesInvoice.status === INVOICE_STATUS_VALIDATED
                    : false
                "
                type="primary"
                icon
                size="sm"
                @click="addSalesPayment(openSalesInvoice)"
                :title="$t('SALES_INVOICES.ADD_PAYMENT')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-money-coins"></i>
                </span>
              </base-button>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="viewSalesOrder(openSalesInvoice)"
                v-if="
                  openSalesInvoiceLoaded ? openSalesInvoice.salesOrder : false
                "
                :title="$t('SALES_INVOICES.VIEW_ORDER')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-bag-17"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelSalesInvoice(openSalesInvoice)"
                v-if="canCancelSalesInvoice"
                :title="$t('SALES_INVOICES.CANCEL')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-remove"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openSalesInvoice"
                :objectType="'SalesInvoices'"
                :objectId="openSalesInvoice.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <!-- <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openSalesInvoiceEditModal(openSalesInvoice)"
                  v-if="
                    openSalesInvoice.status === INVOICE_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_EDIT_SALES_INVOICES)
                  "
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="edit"
                  @click="deleteSalesInvoice(openSalesInvoice)"
                  v-if="
                    openSalesInvoice.status === INVOICE_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_SALES_INVOICES)
                  "
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown> -->

              <button class="close" @click="closeSalesInvoiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-sales-invoice-page
              v-if="openSalesInvoice"
              :salesInvoiceId="openSalesInvoice.id"
              @salesInvoiceLoaded="
                (salesInvoice) => {
                  openSalesInvoice = salesInvoice;
                  openSalesInvoiceLoaded = true;
                }
              "
              @onEditSalesInvoice="openSalesInvoiceEditModal"
              @onDeleteSalesInvoice="deleteSalesInvoice"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSalesInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSalesInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_INVOICE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_INVOICES.EDIT_SALES_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openSalesInvoiceViewModal(openSalesInvoice)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeSalesInvoiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-sales-invoice-page
              v-if="openSalesInvoice"
              :salesInvoiceId="openSalesInvoice.id"
              @onViewSalesInvoice="openSalesInvoiceViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSalesInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSalesInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_INVOICE'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("SALES_INVOICES.ADD_SALES_INVOICE") }}
            </h1>
            <button class="close" @click="closeSalesInvoiceModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-sales-invoice-page
              @onViewSalesInvoice="openSalesInvoiceViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesInvoiceListTable from "./partials/SalesInvoiceListTable.vue";
import EditSalesInvoicePage from "./components/EditSalesInvoiceComponent.vue";
import AddSalesInvoicePage from "./components/AddSalesInvoiceComponent.vue";
import ViewSalesInvoicePage from "./components/ViewSalesInvoiceComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SalesInvoiceListTable,
    NotificationSubscription,
    EditSalesInvoicePage,
    AddSalesInvoicePage,
    ViewSalesInvoicePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelSalesInvoice() {
      if (
        this.openSalesInvoice.status === INVOICE_STATUS_CANCELED ||
        this.openSalesInvoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openSalesInvoice.status === INVOICE_STATUS_VALIDATED) {
        if (this.openSalesInvoice.total_paied != 0) {
          return false;
        }
        return true;
      }
      return false;
    },
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSalesInvoiceModalOpened = false;
    let isEditSalesInvoiceModalOpened = false;
    let isAddSalesInvoiceModalOpened = false;
    let openSalesInvoice = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSalesInvoiceModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSalesInvoiceModalOpened = true;
      }
      openSalesInvoice = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSalesInvoiceModalOpened = true;
    }
    return {
      isViewSalesInvoiceModalOpened: isViewSalesInvoiceModalOpened,
      isEditSalesInvoiceModalOpened: isEditSalesInvoiceModalOpened,
      isAddSalesInvoiceModalOpened: isAddSalesInvoiceModalOpened,
      openSalesInvoice: openSalesInvoice,
      openSalesInvoiceLoaded: false,
      renderKey: 1,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
    };
  },

  methods: {
    openSalesInvoiceCreateModal() {
      this.closeSalesInvoiceModal();
      this.isAddSalesInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openSalesInvoiceViewModal(salesInvoice, reRender = false) {
      this.closeSalesInvoiceModal();
      this.openSalesInvoice = salesInvoice;
      this.isViewSalesInvoiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: { id: this.openSalesInvoice.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openSalesInvoiceEditModal(salesInvoice) {
      this.closeSalesInvoiceModal();
      this.openSalesInvoice = salesInvoice;
      this.isEditSalesInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: { id: this.openSalesInvoice.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeSalesInvoiceModal() {
      this.isAddSalesInvoiceModalOpened = false;
      this.isViewSalesInvoiceModalOpened = false;
      this.isEditSalesInvoiceModalOpened = false;
      this.openSalesInvoiceLoaded = false;
      this.openSalesInvoice = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: {},
        }).href
      );
    },

    async viewSalesOrder(salesInvoice) {
      this.$router.push(this.$objectViewRoute(salesInvoice.salesOrder));
    },

    async validateSalesInvoice(salesInvoice) {
      try {
        await this.$store.dispatch("salesInvoices/validate", salesInvoice.id);
        this.openSalesInvoice =
          this.$store.getters["salesInvoices/salesInvoice"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_VALIDATED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelSalesInvoice(salesInvoice) {
      const confirmation = await swal.fire({
        text: this.$t("SALES_INVOICES.CANCEL_SALES_INVOICE_QUESTION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch("salesInvoices/cancel", salesInvoice.id);
        this.openSalesInvoice =
          this.$store.getters["salesInvoices/salesInvoice"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async addSalesPayment(salesInvoice) {
      this.$router.push({
        name: "Add SalesPayment",
        query: {
          invoice_id: salesInvoice.id,
          amount: salesInvoice.total_remaining_payment,
        },
      });
    },

    async deleteSalesInvoice(salesInvoice) {
      const confirmation = await swal.fire({
        text: this.$t("SALES_INVOICES.DELETE_THIS_SALES_INVOICE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesInvoices/destroy", salesInvoice.id);
          this.renderKey++;
          this.closeSalesInvoiceModal();
          this.$notify({
            type: "success",
            message: this.$t("SALES_INVOICES.SALES_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
