var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('div',{staticClass:"title-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(_vm._s(_vm.salesInvoice.code))])]),_c('div',{staticClass:"col-6 text-right"},[(
            _vm.salesInvoice.status === _vm.INVOICE_STATUS_DRAFT &&
            _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SALES_INVOICES)
          )?_c('base-button',{staticClass:"btn-outline-danger",attrs:{"icon":"","size":"sm"},on:{"click":_vm.deleteSalesInvoice}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])]):_vm._e(),(
            _vm.salesInvoice.status === _vm.INVOICE_STATUS_DRAFT &&
            _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SALES_INVOICES)
          )?_c('base-button',{staticClass:"elite-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.editSalesInvoice}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-edit"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])]):_vm._e()],1)])]),_c('div',{staticClass:"all-infos"},[_c('div',{staticClass:"all-infos-text"},[_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.salesInvoice.created_at,"LLLL"))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))]),_c('dd',[_c('location',{attrs:{"salesInvoice":_vm.salesInvoice.id}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',[_c('organization',{attrs:{"organization":_vm.salesInvoice.organization}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATUS")))]),_c('dd',[_c('sales-invoice-status-badge',{attrs:{"salesInvoice":_vm.salesInvoice}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.TOTAL")))]),(_vm.salesInvoice.pricing)?_c('dd',[_c('span',[_vm._v(" "+_vm._s(_vm.$formatCurrency(_vm.salesInvoice.pricing.total))+" ")])]):_vm._e()]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.TOTAL_PAIED")))]),_c('dd',[_c('span',[_vm._v(" "+_vm._s(_vm.$formatCurrency( _vm.salesInvoice.total_paied ? _vm.salesInvoice.total_paied : 0 ))+" ")])])]),_c('dl',{staticClass:"row"},[(_vm.salesInvoice.excerpt)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t("COMMON.NOTE"))+" ")]):_vm._e(),(_vm.salesInvoice.excerpt)?_c('dd',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.salesInvoice.excerpt)}})]):_vm._e()]),_c('div',{staticClass:"col-12 text-center mb-5 mt-5"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))])]),(
          _vm.salesInvoice.billing_entity_type === _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
        )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LASTNAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_lastname)+" ")])]):_vm._e(),(
          _vm.salesInvoice.billing_entity_type === _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
        )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.FIRSTNAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_firstname)+" ")])]):_vm._e(),(
          _vm.salesInvoice.billing_entity_type === _vm.BILLING_ENTITY_TYPE_COMPANY
        )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.COMPANY_NAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_company_name)+" ")])]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.COUNTRY")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_country)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_state)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CITY")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_city)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ZIPCODE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_zipcode)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ADDRESS")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_address)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.EMAIL")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.salesInvoice.billing_email)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }